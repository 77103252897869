import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect} from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { create } from "ipfs-http-client";
import { saveAs } from "file-saver";


const projectId = '2Gs5eCtdL6OIzgNXsAlMD3ysvHT';
const projectSecret = 'b1a96c56098c7e190b8e15c56df5f1cd';
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');
const client = create({ 
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
  headers: {
    authorization: auth
  }
})

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;
export const StyledButton = styled.button`
font-family: coder;
  cursor: pointer;
  width: auto;
  height: 5%;
  background: transparent;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;           
  border: 1px black solid; 
  color: black;
  padding: 2rem;

:hover {
  background-color: rgb(204, 204, 204);
}
`;
export const StyledConnectButton = styled.button`
font-family: coder;
position: absolute;
top: 0%;
right: 0%;
cursor: pointer;
background: rgba(250, 250, 250, 0.7);
text-align: center;
text-transform: uppercase;
transition: 0.5s;           
border: none; 
display: block;
font-size: 2vw;
color: black;

:hover {
transform: scale(1.05);
}
`;

export const StyledRoundButton = styled.button`
background: transparent;
  border: none;
  color: black;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  font-size: 2vw;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  border-radius: 50%;
  :hover{
    opacity: 0.9;
    transform: scale(1.2);
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
color: #2ca5ff;
text-decoration: none;
:hover{
  opacity: 0.9;
  color: #2ca5ff;
}
`;



function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const ipfsBaseUrl = "ipfs://";//change to "ipfs//"
  const [paused, setPaused] = useState(false);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    FREE_WEI_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    TWITTER_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getPaused = async () => {
    if (blockchain.smartContract !== null){
    let res = await blockchain.smartContract.methods
    .paused()
    .call();
    setPaused(res);}
  };

  const ClaimNFT = (_uri) => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    if (paused == true) {gasLimit = 1300000000;alert("Contract is Paused");return; }
    let totalCostWei = String(cost);
    let totalGasLimit = String(gasLimit );
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, _uri)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Something Went Wrong");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Thank you for minting`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
      
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
        
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  
  const createMetaDataAndMint = async (_name, _des, _imgBuffer) =>{
    try {
      const addedImage = await client.add(_imgBuffer);
      const metaDataObj = {
      name: _name,
      description: _des,
      image: ipfsBaseUrl + addedImage.path,
      };
      const addedMetaData = await client.add(JSON.stringify(metaDataObj));
      console.log(addedMetaData);
      ClaimNFT(ipfsBaseUrl + addedMetaData.path);
    } catch (error) {
      console.log(error);
    }
 };

 const startMintingProccess = () =>{
  const fromArtInput = document.getElementById("fname");
    if (fromArtInput.value == "") {
      fromArtInput.value = "GMachine";
    }
    const fromDescriptionInput = document.getElementById("fname-description");
    if (fromDescriptionInput.value == "") {
      fromDescriptionInput.value = "GMachine is an fun art project that uses GM culture as the brush. Free to download or mint it forever on the blockchain and apart of the collection. A project made by Vakkuda";
    }
   createMetaDataAndMint(fromArtInput.value, fromDescriptionInput.value, getImageData() );
 };

  const getImageData = () =>{
    const canvasEl = document.getElementById("canvas");
    let dataUrl = canvasEl.toDataURL("image/png");
    const buffer = Buffer(dataUrl.split(",")[1], "base64");
    return buffer;
 };

 const localImageSaveData = () =>{
  var canvas = document.getElementById("canvas");
// draw to canvas...
canvas.toBlob(function(blob) {
  const fromArtInput = document.getElementById("fname");
  if (fromArtInput.value == "") {
    fromArtInput.value = "GMachine";
  }
    saveAs(blob, `${fromArtInput.value}.png`);
});
 };
 const ConnectImageSaveData = () =>{
  var canvas = document.getElementById("canvas");
// draw to canvas...
canvas.toBlob(function(blob) {
    saveAs(blob, "Gmachine.png");
});
 };


  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
   
  }, [blockchain.account]);
  
  getPaused();
  return (
    
      <s.Container
        flex={1}
        ai={"center"} jc={"center"} style={{
          
        }}
        
      >
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  
                  <s.Container ai={"center"} jc={"center"}fd={"row"} >
                   <s.SpacerMedium /><s.SpacerMedium />
                   
                    <div
                    style={{
                      fontWeight: "bold",
                      zIndex:1}}
                      id="connect"
                    className="container-gif"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                   Connect
                    </div>
                    <s.SpacerSmall/>
                    <div
                    className="container-download"
                      style={{
                        fontWeight: "bold",
                        pointerEvents: "all",
                        zIndex:1}}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault(); 
                          ConnectImageSaveData();
                        }}
                      >
                        
                        {claimingNft ? "Downloading" : "Download"}
                      </div> 
                      <h1 id="text">Made by vakkuda.eth</h1>
                      <a target={"_blank"} href={"https://opensea.io/collection/gmachine"}><h1 id="Opensea">Opensea</h1></a>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        
                        <s.TextDescription
                          style={{
                            zIndex:1,
                            textAlign: "center",
                            color: "black",
                            position:"absolute",
                            right: "5%"
                            
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                    
                  </s.Container>
                ) : ( 
                  
                  < >
                  <s.Container
                  style={{
                    background: "rgba(255,255,255, 0.4)",
                    backdropFilter:"blur(15px)",
                    borderInline: "3px black solid",
                    borderTop: "3px black solid",
                    position: "fixed",
                    width: "60%",
                    height: "auto",
                    bottom: "0%",
                    borderRadius: "0.2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "2",
                         }}>   
                        <a target={"_blank"} href={"https://opensea.io/collection/gmachine"}><h1 id="Opensea2">Opensea</h1></a>
                  <s.SpacerMedium />
                  <s.SpacerMedium />
                  <s.Container style={{width:"auto",borderRadius: "0.2rem", background: "rgba(0,0,0, 0.2)",}} ai={"center"} jc={"center"} fd={"row"}>
            <s.TextTitle
                  style={{marginLeft: "5px",marginBlock:"5px", fontSize: "max(1vw,10px)", zIndex:1, textAlign: "center", 
                  color: "black",
                  }}
                > 
                Art Name:
                </s.TextTitle>
                <input 
                  style={{
                  pointerEvents: "all",
                  zIndex:1}} 
                  type="text" 
                  id="fname"/>
                        <s.SpacerMedium />
                        </s.Container>
                        <s.SpacerSmall />
                        <s.Container style={{width:"auto",borderRadius: "0.2rem", background: "rgba(0,0,0, 0.2)",}} ai={"center"} jc={"center"} fd={"row"}>
            <s.TextTitle
                  style={{ marginLeft: "5px",marginBlock:"5px",fontSize: "max(1vw,10px)", zIndex:1, textAlign: "center", 
                  color: "black",
                  }}
                > 
                Art Description:
                </s.TextTitle>
                <input 
                  style={{
                  pointerEvents: "all",
                  zIndex:1}} 
                  type="text" 
                  id="fname-description"/>
                        <s.SpacerMedium />
                        </s.Container>
                        <s.SpacerMedium />
                  <s.Container style={{maxWidth:"90%"}} ai={"center"} jc={"center"} fd={"row"}>
                  <StyledButton
                    className="mintbtn"
                      style={{
                        fontSize: "max(2vw, 15px)",
                        fontWeight: "bold",
                        pointerEvents: "all",
                        zIndex:1}}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault(); 
                          localImageSaveData();

                        }}
                      >
                        
                        {claimingNft ? "Downloading" : "Download"}
                      </StyledButton> 
                      <s.SpacerSmall />
                      <s.TextTitle
                  style={{ fontSize: "max(1vw,10px)", zIndex:1, textAlign: "center", 
                  color: "black",
                  }}
                > 
                OR
                </s.TextTitle>
                <s.SpacerSmall />
                      <StyledButton
                    className="mintbtn"
                      style={{
                        fontSize: "max(2vw, 15px)",
                        fontWeight: "bold",
                        pointerEvents: "all",
                        zIndex:1}}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault(); 
                          startMintingProccess();
                          getData();
                        }}
                      >
                        
                        {claimingNft ? "Minting" : "Mint"}
                      </StyledButton> 
                      
                    </s.Container>
      <s.SpacerMedium />
                    
            <s.Container ai={"center"} jc={"center"} fd={"column"}>
            <s.SpacerSmall />
            
                      </s.Container>
                      
                <s.SpacerLarge />
                <s.TextDescription
                      style={{
                        zIndex:1,
                        fontSize: "max(1vw, 15px)",
                        padding:"15px",
                        maxWidth:"90%",
                        color: "black",
                        
                      }}
                    >
                    {feedback}
                    </s.TextDescription>
                <s.SpacerLarge />
                <h1 id="text-2">Download is free / O.001 eth to mint</h1>
                      </s.Container>
                  </>
                )}

            

      </s.Container>
  );
  
}
export default App;

